import { Controller } from "@hotwired/stimulus"

import Swal from 'sweetalert2';
// window.Swal = Swal;

import locales from '../init/swal-translations/locales.json';

// Connects to data-controller="swal"
export default class extends Controller {
  defaultLang = 'it';

  confirmDelete(event){
    let tl = locales[this.element.dataset['locale']||this.defaultLang]['delete'];
    let requestedUrl = this.element.href;

    event.preventDefault()
    Swal.fire({
      title: tl.title,
      text: tl.text,
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#dc3545',
      cancelButtonText: tl.cancel,
      confirmButtonText: tl.accept,
    })
    .then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          method: 'DELETE',
          url: requestedUrl,
          data: {format: 'json'},
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          success: function(){
            Swal.fire({
              title: tl.success,
              icon: 'success',
              confirmButtonText: tl.close
            }).then(function(closed){
              window.location.reload()
            })
          }
        })
      }
    })
  }

  confirmAction(event){
    let tl = locales[this.element.dataset['locale']||this.defaultLang]['default'];
    let requestedUrl = this.element.href;
    let method = this.element.dataset['method'] || 'GET';

    event.preventDefault()
    Swal.fire({
      title: tl.title,
      text: tl.text,
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: 'var(--bs-primary)',
      cancelButtonText: tl.cancel,
      confirmButtonText: tl.accept,
    })
    .then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          method: method,
          url: requestedUrl,
          data: {format: 'json'},
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          success: function(){
            Swal.fire({
              title: tl.success,
              icon: 'success',
              confirmButtonText: tl.close
            }).then(function(closed){
              window.location.reload()
            })
          }
        })
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"

export default class extends Controller {
  connect() {
    let dz = this.element;
    // hide file field
    $(dz).find('#dz-file-field').hide();

    this.dropzone = new Dropzone(dz, {
      url: $(dz).data('upload-url'),
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dictDefaultMessage: $(dz).data('default-msg'),
      dictRemoveFile: $(dz).data('delete-msg'),
      addRemoveLinks: $(dz).data('can-remove'),
      maxFiles: $(dz).data('maxfiles') || 1,
      init: function(){
        let myDropzone = this;
        let presentimgs = $(dz).data('present-imgs')
        presentimgs.forEach(el => {
          let fileobj = {name: el.filename, size: el.size, id: el.id};
          myDropzone.displayExistingFile(fileobj, el.url);
          myDropzone.emit("complete", fileobj);
          myDropzone.files.push(fileobj);
        });

        myDropzone.on('addedfile', function(file) {
          while (myDropzone.files.length > myDropzone.options.maxFiles){
            myDropzone.removeFile(myDropzone.files[0])
          };
        });

        myDropzone.on('removedfile', function(file){
          let id = file.id;
          $.ajax({
            url: `/media/${id}`,
            method: 'DELETE',
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            success: function(){
              console.log('deleted');
            }
          })
        });
      }
    });
  }
}

// Huge: FontAwesome completo, non ha bisogno di altri import ma è sconsigliato per le dimensioni
//         yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/all';


// Medium: import delle singole varianti, quindi dimensioni ridotte rispetto alla versione completa,
//         ma comunque abbastanza importanti in quanto ogni variante ha svariate centinaia di icone;
//         l'ultimo import (js/fontawesome) è necessario per il buon funzionamento
//           yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/solid';
// import '@fortawesome/fontawesome-pro/js/regular';
// import '@fortawesome/fontawesome-pro/js/brands';
// import '@fortawesome/fontawesome-pro/js/light';
// import '@fortawesome/fontawesome-pro/js/duotone';
// import '@fortawesome/fontawesome-pro/js/fontawesome';


// Small: include solo le icone utilizzate, quindi lo spazio è ridotto al minimo
//        eseguire l'apposito task per aggiornare la lista degli import tra i commenti BEGIN ed END sottostanti,
//        da utilizzare ogni volta che si aggiunge o rimuove un'icona:
//          rails fontawesome:repack
//        Per utilizzarlo sono necessari i seguenti pacchetti:
//          yarn add @fortawesome/pro-{solid,regular,light,duotone}-svg-icons@next @fortawesome/fontawesome-svg-core@next
//
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// BEGIN fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
import {
  faAt as farAt,
  faCircleCheck as farCircleCheck,
  faCircleQuestion as farCircleQuestion,
  faCircleXmark as farCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faFacebook as fabFacebook,
  faInstagram as fabInstagram,
  faLinkedin as fabLinkedin,
  faTwitter as fabTwitter,
  faVimeo as fabVimeo,
  faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft as fasArrowLeft,
  faCheck as fasCheck,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faClipboardCheck as fasClipboardCheck,
  faEdit as fasEdit,
  faEuroSign as fasEuroSign,
  faFileCsv as fasFileCsv,
  faFileExcel as fasFileExcel,
  faList as fasList,
  faLock as fasLock,
  faMagic as fasMagic,
  faPersonThroughWindow as fasPersonThroughWindow,
  faPlus as fasPlus,
  faSave as fasSave,
  faSignInAlt as fasSignInAlt,
  faTimes as fasTimes,
  faUndo as fasUndo,
  faUndoAlt as fasUndoAlt,
  faUser as fasUser,
  faUserPlus as fasUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowUpRightFromSquare as fadArrowUpRightFromSquare,
  faBinaryCircleCheck as fadBinaryCircleCheck,
  faPaperPlane as fadPaperPlane,
  faPaperPlaneTop as fadPaperPlaneTop,
  faRectangleXmark as fadRectangleXmark,
  faSignInAlt as fadSignInAlt,
  faUnlock as fadUnlock,
  faUserPlus as fadUserPlus,
  faUserXmark as fadUserXmark,
} from '@fortawesome/pro-duotone-svg-icons';
library.add(fabFacebook, fabInstagram, fabLinkedin, fabTwitter, fabVimeo, fabYoutube, fadArrowUpRightFromSquare, fadBinaryCircleCheck, fadPaperPlane, fadPaperPlaneTop, fadRectangleXmark, fadSignInAlt, fadUnlock, fadUserPlus, fadUserXmark, farAt, farCircleCheck, farCircleQuestion, farCircleXmark, fasArrowLeft, fasCheck, fasChevronLeft, fasChevronRight, fasClipboardCheck, fasEdit, fasEuroSign, fasFileCsv, fasFileExcel, fasList, fasLock, fasMagic, fasPersonThroughWindow, fasPlus, fasSave, fasSignInAlt, fasTimes, fasUndo, fasUndoAlt, fasUser, fasUserPlus);
// END fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
dom.watch();

// Fix fullcalendar bug: https://github.com/fullcalendar/fullcalendar/issues/5544#issuecomment-658406050
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoReplaceSvg = 'nest';
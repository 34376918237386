// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

/** JQUERY **/
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

/** ALPINEJS **/
import Alpine from 'alpinejs'
window.Alpine = Alpine;
Alpine.start();

/** BOOTSTRAP **/
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))  
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

/** PLUGINS **/
// datatable https://datatables.net/download/
require('datatables.net-bs4')();
require('datatables.net-autofill-bs4')();
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-responsive-bs4')();
require('datatables.net-select-bs4')();
// datepicker
require('bootstrap-datepicker');
// inline editable
require('./bootstrap-editable');
// jquery steps
require('./jquery.steps.min');
// metismenu (sidebar)
require('metismenu');
// jquery autocomplete
require('./jquery.autocomplete.min');
// jquery repeater
require('jquery.repeater');
// inputmask
require('inputmask');
// simplebar
import 'simplebar';

/** CUSTOM **/
require('./fontawesome');
require('./locale');
require('./plugins');
require('./main');
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    this.element.addEventListener('change', function(){
      const frame = document.getElementById(this.dataset.target);
      frame.src=this.options[this.selectedIndex].dataset.url;
      frame.reload();
    });
  }
}
